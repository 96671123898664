import type { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { message } from 'antd';
import axios from 'axios';
import { store } from '@/store';
import { currentEnv, proxyUrl } from '@/constant/url';
import { cookie } from '@/utils/cookie';
import {
  COOKIE_TOKEN_NAME,
  COOKIE_REFRESH_TOKEN_NAME,
  PERMISSION,
  IGNORE_API,
  XSS_TRANSFER_API,
  SWITCH_SUPERVISION_API,
} from '@/constant';
import { getRefreshToken } from '@/apis/app';
import { AxiosCanceler } from './axiosCancel';
import { xssPreventTransferApi } from '@/utils';

const NO_AUTH_STATUS_CODE = [403];

console.log(currentEnv, 'currentEnv');
console.log(process.env, 'process.env');

class Request {
  private request(opts: {}) {
    const instance: AxiosInstance = axios.create({
      baseURL: currentEnv === 'dev' ? proxyUrl : '',
      timeout: 80000,
      headers: {
        'content-type': '',
      },
      withCredentials: true,
    });
    this.setInterceptors(instance);
    return instance(opts);
  }

  // 退出登录
  logOut() {
    store.dispatch.app.setLoginState('');
    message.warning('The session is invalid. Please login again');
    window.location.href = '/login';
    localStorage.setItem(PERMISSION, '[]');
    cookie.delete(COOKIE_TOKEN_NAME, '/');
    cookie.delete(COOKIE_REFRESH_TOKEN_NAME, '/');
  }

  // 刷新token
  async refreshToken(newToken, res) {
    const response = await getRefreshToken({ token: newToken });
    const data = response.data;
    const refreshToken = data?.refreshToken?.token;
    if (data?.accessToken) {
      const accessToken = data.accessToken.token;
      cookie.set(COOKIE_TOKEN_NAME, accessToken, null, '/');
      cookie.set(COOKIE_REFRESH_TOKEN_NAME, refreshToken, null, '/');
      store.dispatch.app.setUserInfo(data);
      if (res.config.method === 'post') {
        res.config.data = res?.config?.data ? JSON.parse(res.config.data) : {};
      }
      return this.request(res.config);
    }
  }

  // 拦截器
  private setInterceptors(instance: AxiosInstance) {
    const axiosCanceler = new AxiosCanceler();
    const accessToken = cookie.get(COOKIE_TOKEN_NAME);
    const currentRegulator =
      sessionStorage.getItem('currentRegulator') ||
      localStorage.getItem('currentRegulator') ||
      store.getState().app.currentRegulator;

    // 请求拦截器
    instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      // 泳道
      let swimmingRoad = {};
      // 如果不是IGNORE_URL的接口, 就取消在请求队列里的请求
      if (!IGNORE_API.includes(config.url as string)) axiosCanceler.removeAllPending();
      // 由于floating api请求时间过长，所以为了不影响其他请求，在翻页或多次触发时加入请求队列，随时取消
      if (config.url?.includes('queryClientFloating')) axiosCanceler.addPending(config);
      console.log(process.env.TAG);
      if (currentEnv !== 'prod')
        swimmingRoad = {
          branchVersion: process.env.BRANCH_VERSION || process.env.REACT_APP_BRAND_VERSION,
          TAG: process.env.TAG,
        };
      config.headers = {
        Regulator: currentRegulator,
        Language: store.getState().app.lang,
        timezone: new Date().getTimezoneOffset() / -60,
        Authorization: accessToken && accessToken !== 'null' ? `Bearer ${accessToken}` : '',
        ...swimmingRoad,
        // 系统认为AxiosRequestHeaders与声明的差异化较大，所以要先断言为unknown后再断言为AxiosRequestHeaders
      } as unknown as AxiosRequestHeaders;
      return config;
    });
    // 响应拦截器
    instance.interceptors.response.use(
      (res) => {
        res && axiosCanceler.removePending(res.config);
        if (res.status === 200) {
          if (SWITCH_SUPERVISION_API?.includes(res?.config?.url!) && res.data.code === '40001') {
            // 记录当前url
            window.sessionStorage.setItem('currentUrl', window.location.href);
            window.location.href = '/403?code=40001';
          }

          if (XSS_TRANSFER_API?.includes(res?.config.url!)) {
            res.data.data = xssPreventTransferApi(res?.data?.data);
          }
          return Promise.resolve(res.data);
        }
        return Promise.reject(res.data);
      },
      (err) => {
        if (err.code === 'ERR_CANCELED') {
          // aborted in useEffect cleanup
          return Promise.resolve({});
        }
        const resStatus = err.response?.status || 0;
        if (resStatus === 401) {
          this.logOut();
        } else if (NO_AUTH_STATUS_CODE.includes(resStatus)) {
          // 没有权限
          window.location.href = '/403';
        } else if (err?.response?.data?.message) {
          message.error(err?.response?.data?.message);
        } else if (resStatus) {
          message.error(
            `Brower status: ${err?.response?.status},The Internet Error,Please Try Again!`
          );
        }
        return Promise.reject(err);
      }
    );
  }

  // 封装get请求
  public get(url: string, params?: any, config?: any) {
    return this.request({
      url,
      method: 'get',
      params,
      ...config,
    });
  }

  // 封装post请求
  public post(url: string, data?: any, config?: any) {
    return this.request({
      url,
      method: 'post',
      data,
      ...config,
    });
  }

  // 封装get请求
  public rcGet(url: string, token: string, params?: string) {
    return this.request({
      url,
      method: 'get',
      headers: {
        'RC-Authorization': `Bearer ${token}`,
      },
      params,
    });
  }

  public rcPost(url: string, token: string, data?) {
    return this.request({
      url,
      method: 'post',
      headers: {
        'RC-Authorization': `Bearer ${token}`,
      },
      data,
    });
  }
}
export default Request;

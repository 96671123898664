const isDevEnv = process.env.REACT_APP_ENV === 'dev';

export const enum TASK_TYPE {
  CUSTOM_TASK = 1,
  ASSIGNED_TASK = 2,
}

export const LEADS_FILTER = {
  TODAY: 'Today',
  MONTH: 'Month',
  FIRST_TRADE_ACCOUNT: 'First TradeIn Demo Account',
  INACTIVE: 'Inactive Demo Account',
};

export const RING_CENTRAL_CALLBACK_SATE = {
  HOME: 'home',
  TASK: 'task',
  DETAIL_CLIENT: 'clientdetail',
  DETAIL_LEAD: 'clientlead',
  LIST_CLIENT: 'clientlist',
  LIST_LEAD: 'leadlist',
  TEST: 'testcall',
  MESSAGE: 'message',
  IB: 'ib',
  CPA: 'cpa',
};

export const RING_CENTRAL_CALLBACK_ROUTE = {
  [RING_CENTRAL_CALLBACK_SATE.HOME]: '/salework-station/dashboard',
  [RING_CENTRAL_CALLBACK_SATE.TASK]: '/tasks',
  [RING_CENTRAL_CALLBACK_SATE.DETAIL_CLIENT]: '/clients/information/',
  [RING_CENTRAL_CALLBACK_SATE.DETAIL_LEAD]: '/clients/information/',
  [RING_CENTRAL_CALLBACK_SATE.LIST_CLIENT]: '/clients',
  [RING_CENTRAL_CALLBACK_SATE.LIST_LEAD]: '/leads',
  [RING_CENTRAL_CALLBACK_SATE.TEST]: '/test/call',
};

export const PERMISSION_MAP = new Map([
  ['menu.sws.dashboard', '/salework-station/dashboard'],
  ['menu.sws.tasks', '/tasks'],
  ['menu.sws.messages', '/messages'],
  ['menu.sws.lead-management', '/leads'],
  ['menu.sws.client-management', '/clients'],
  ['menu.sws.sales-note', '/sales-note'],
  ['menu.sws.admin-statistics', '/adminStatistics'],
  ['menu.sws.admin-statistics', '/adminStatistics'],
  ['menu.sws.admin-statistics', '/adminStatistics'],
  ['sws.v2.tasks', '/tasksV2'],
  // ['sws.v2.lead-management', '/leadsV2'],
  ['sws.v2.client-management', '/clientsV2'],
  ['sws.v2.ib-management', '/partnerManagement/ibManagement'],
  ['sws.v2.cpa-management', '/partnerManagement/cpaManagement'],
  ['sws.v2.messages', '/messagesV2'],
  ['sws.v2.sales-note', '/sales-noteV2'],
  ['sws.v2.dashboard', '/salework-station/dashboardV2'],
  ['sws.v2.lead-management', '/referralLink'],
]);

export const COOKIE_TOKEN_NAME = 'swsToken';
export const COOKIE_REFRESH_TOKEN_NAME = 'swsRefreshToken';
export const PERMISSION = 'permission';
export const PERMISSION_V2 = 'permissionV2';
export const NO_REQUEST_AUTH_TOKEN_LIST = [
  '/api/v1/auth/session/login',
  '/api/v1/auth/session/logout',
];

export const CLIENT_TO_IB = [5, 16];

export const CLIENT_COLUMNS_SETTING = 'sws-client-list-setting';
export const LEADS_COLUMNS_SETTING = 'sws-lead-list-setting';
export const SALES_NOTE_COLUMNS_SETTING = 'sws-sales-note-list-setting';
export const REPORTS_WITHDRAWALS_COLUMNS_SETTING = 'sws-reports-withdrawals-list-setting';
export const REPORTS_DEPOSITS_COLUMNS_SETTING = 'sws-reports-deposits-list-setting';
export const REPORTS_KYC_COLUMNS_SETTING = 'sws-reports-kyc-list-setting';
export const REPORTS_ACCOUNT_COLUMNS_SETTING = 'sws-reports-account-list-setting';
export const REPORTS_WHOLESALE_COLUMNS_SETTING = 'sws-reports-wholesale-list-setting';
export const REPORTS_COPY_TRADING_COLUMNS_SETTING = 'sws-reports-copyTrading-list-setting';
export const REPORTS_PERFORMANCE_COLUMNS_SETTING = 'sws-reports-performance-list-setting';
export const PARTNER_IB_COLUMNS_SETTING = 'sws-partner-ib-list-setting';
export const PARTNER_CPA_COLUMNS_SETTING = 'sws-partner-cpa-list-setting';
export const PROFILE_LEADS_COLUMNS_SETTING = 'swsV2-profile-client-list-setting';
export const CLIENT_COLUMNS_SETTING_V2 = 'sws-client-list-setting_v2';
export const LEADS_COLUMNS_SETTING_V2 = 'sws-lead-list-setting_v2';
export const REPORTS_KYC_COLUMNS_SETTING_V2 = 'sws-reports-kyc-list-setting_v2';
export const REPORTS_ACCOUNT_COLUMNS_SETTING_V2 = 'sws-reports-account-list-setting_v2';
export const REPORTS_DEPOSITS_COLUMNS_SETTING_V2 = 'sws-reports-deposits-list-setting_v2';
export const REPORTS_WHOLESALE_COLUMNS_SETTING_V2 = 'sws-reports-wholesale-list-setting_v2';
export const REPORTS_WITHDRAWALS_COLUMNS_SETTING_V2 = 'sws-reports-withdrawals-list-setting_v2';
export const SALES_NOTE_COLUMNS_SETTING_V2 = 'sws-sales-note-list-setting_v2';

// Black list constant
export const BLACK_LIST_OPTIONS = [
  {
    label: 'No Blacklist',
    value: 2,
  },
  {
    label: 'AT - Prohibit Account Transfer',
    value: 3,
  },
  {
    label: 'NTA - Note for Account Transfer',
    value: 4,
  },
  {
    label: 'RJ - Prohibit to submit withdrawals',
    value: 5,
  },
  {
    label: 'NT - Note for withdrawals',
    value: 6,
  },
  {
    label: 'RD - Prohibit to submit deposits',
    value: 7,
  },
  {
    label: 'NTD - Note for deposits',
    value: 8,
  },
  {
    label: 'RA - Prohibit to submit commissions',
    value: 9,
  },
  {
    label: 'NA - Note for commissions',
    value: 10,
  },
  {
    label: 'CA - Close Account',
    value: 11,
  },
  {
    label: 'WO - Prohibit to submit W/O',
    value: 12,
  },
  {
    label: 'CO - Note for Close Only',
    value: 13,
  },
  {
    label: 'MM-Mark and Manual Audit',
    value: 14,
  },
];
export const ALL_BLACK_LIST = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
export const MAX_SEARCH_NUMBER = 2147483647;
export const SENSITIVE_TEXT = '****';
export const SENSITIVE_NAME = 'menu.sws.security.sensitive-info';
export const DATABASE_SEARCH_SENSITIVE_NAME = 'menu.sws.general.search.sensi';
export const SENSITIVE_NAME_V2 = 'sws.v2.security.sensitive-info';
export const DATABASE_SEARCH_SENSITIVE_NAME_V2 = 'sws.v2.general.search.sensi';
export const PROFILE_BREAD_INDEX = {
  lead: 'lead',
  client: 'client',
  kyc: 'kyc',
  deposit: 'deposit',
  withdraw: 'withdraw',
  account: 'account',
  wholesaleAudit: 'wholesaleAudit',
  copyTrading: 'copyTrading',
  ib: 'IB',
  cpa: 'CPA',
  leadV2: 'leadV2',
  clientV2: 'clientV2',
  ibRebate: 'ibRebate',
  copyTradingV2: 'copyTradingV2',
  kycV2: 'kycV2',
  depositV2: 'depositV2',
  withdrawV2: 'withdrawV2',
  accountV2: 'accountV2',
  wholesaleAuditV2: 'wholesaleAuditV2',
};
export const FILE_PREVIEW_DOWNLOAD_TYPES = [
  'pdf',
  'PDF',
  'docx',
  'DOCX',
  'doc',
  'DOC',
  'xlsx',
  'XLSX',
  'xls',
  'XLS',
  'csv',
  'CSV',
];

// client list 中包含的所有接口不能取消Floating p/l的请求
export const IGNORE_API = [
  '/api/v1/tasks/taskList',
  '/api/v1/users/followup/all/info',
  '/api/v1/profile/getClientBlackList',
  '/api/v1/profile/overView',
  '/api/v1/decrypt/email-or-phone',
  '/api/v1/enums/salesList',
  '/api/v1/message/getMessageList',
  '/api/v1/tasks/taskList',
  '/api/v1/enums/options',
  '/api/v1/users/querySalesAndIbsList',
  '/api/v1/users/querySalesAndIbsAllList',
];

// 所有包含有可能产生xss攻击的回显
export const XSS_TRANSFER_API = [
  '/api/v1/enums/salesList',
  '/api/v1/viewFilter/list',
  '/api/v1/user/getUserInfo',
  '/api/v1/profile/overView',
  '/api/v1/users/management/clientList',
  '/api/v1/users/management/leadsList',
  '/api/v1/reports/kyc/kycList',
  '/api/v1/deposit/queryDepositReportsList',
  '/api/v1/report/withdraw/list',
  '/api/v1/salesNote/getSalesNoteList',
  '/api/v1/salesNote/fileupload',
  '/api/v1/message/getMessageList',
  '/api/v1/tasks/taskList',
  '/api/v1/users/followup/all/info',
  '/api/v1/users/followup/additional/information/initialization',
  '/api/v1/profile/getClientDetails',
  '/api/v1/profile/getAuditInfo',
  '/api/v1/profile/followUpLogList',
  '/api/v1/web/call/list',
  '/api/v1/profile/getCampaignParticipation',
  '/api/v1/tickets/queryTicketList',
  '/api/v1/sales/group/getSalesGroupList',
  '/api/v1/sales/group/getSalesList',
  '/api/v1/statistics/template/getStatisticsTemplateList',
  '/api/v1/statistics/template/getSalesGroup',
  '/api/v1/tierManagement/getTierManagementList',
  '/api/v1/tierManagement/getTierManagementInfo',
  '/api/v1/users/management/listSalesUserInfo',
  '/api/v1/profile/getUserLogList',
  '/api/v1/email/list',
  '/api/v1/reports/wholesale/list',
  '/api/v1/email/detail',
  '/api/v1/profile/clientName',
  '/api/v1/report/accountAudit/list',
  '/api/v1/profile/getVantageRewardsUserData',
  '/api/v1/profile/getVantageRewardsRedemptionData',
  '/api/v2/enums/salesList',
  '/api/v2/viewFilter/list',
  '/api/v2/user/getUserInfo',
  '/api/v2/profile/overView',
  '/api/v2/users/management/clientList',
  '/api/v2/users/management/leadsList',
  '/api/v2/reports/kyc/kycList',
  '/api/v2/deposit/queryDepositReportsList',
  '/api/v2/report/withdraw/list',
  '/api/v2/salesNote/getSalesNoteList',
  '/api/v2/salesNote/fileupload',
  '/api/v2/message/getMessageList',
  '/api/v2/tasks/taskList',
  '/api/v2/users/followup/all/info',
  '/api/v2/users/followup/additional/information/initialization',
  '/api/v2/profile/getClientDetails',
  '/api/v2/profile/getAuditInfo',
  '/api/v2/profile/followUpLogList',
  '/api/v2/web/call/list',
  '/api/v2/profile/getCampaignParticipation',
  '/api/v2/tickets/queryTicketList',
  '/api/v2/sales/group/getSalesGroupList',
  '/api/v2/sales/group/getSalesList',
  '/api/v1/statistics/template/getStatisticsTemplateList',
  '/api/v2/statistics/template/getSalesGroup',
  '/api/v2/tierManagement/getTierManagementList',
  '/api/v2/tierManagement/getTierManagementInfo',
  '/api/v2/users/management/listSalesUserInfo',
  '/api/v2/profile/getUserLogList',
  '/api/v2/email/list',
  '/api/v2/reports/wholesale/list',
  '/api/v2/email/detail',
  '/api/v2/profile/clientName',
  '/api/v2/report/accountAudit/list',
  '/api/v2/profile/getVantageRewardsUserData',
  '/api/v2/profile/getVantageRewardsRedemptionData',
  '/api/v2/leadSource/list',
  '/api/v2/salesNote/getSalesNoteInfoById',
  '/api/v1/salesNote/getSalesNoteInfoById',
];

export const FILTER_MODULE_INDEX = {
  lead: 'Lead Management',
  client: 'Client Management',
  salesNote: 'Sales Note',
  reportKYC: 'Reports_KYC',
  reportDeposit: 'Reports_Deposits',
  reportWithdrawal: 'Reports_Withdrawals',
  reportAccountAudit: 'Reports_AccountAudit',
  reportWholesale: 'Reports_Wholesale',
  reportCopyTrading: 'Reports_CopyTrading',
  pantnerIb: 'IB Management',
  pantnerCPA: 'CPA Management',
};

// transferPage url
export const TRANSFER_PAGE_PATH = isDevEnv
  ? 'http://localhost:3000/transfer'
  : `${window.location.protocol}//${window.location.hostname}/transfer`;

export const TABLE_PAGINATION_SIZE_CHANGER = (total: number) => {
  // 组件默认是大于50条展示，需求改为大于10条
  return {
    showSizeChanger: (total && total > 10) || false,
  };
};
// tier list options
export const TIERS_LIST_OPTIONS = [
  {
    label: 'No Client Tiers',
    value: -1,
  },
  {
    label: 'Tier 1',
    value: 1,
  },
  {
    label: 'Tier 2',
    value: 2,
  },
  {
    label: 'Tier 3',
    value: 3,
  },
  {
    label: 'Tier 4',
    value: 4,
  },
  {
    label: 'Tier 5',
    value: 5,
  },
  {
    label: 'Tier 6',
    value: 6,
  },
  {
    label: 'Others',
    value: -2,
  },
];

export const IS_SHOW_REGULATOR_BTN = localStorage.getItem('currentVersion') === 'v1';

// 切换监管导致用户查不到,相关接口单独处理特殊code值
export const SWITCH_SUPERVISION_API = [
  '/api/v1/users/followup/all/info',
  '/api/v2/users/followup/all/info',
  '/api/v1/users/extends/basic/info',
  '/api/v2/users/extends/basic/info',
  '/api/v1/profile/overView',
  '/api/v2/profile/overView',
  '/api/v1/profile/clientName',
  '/api/v2/profile/clientName',
  '/api/v1/profile/getClientDetails',
  '/api/v2/profile/getClientDetails',
  '/api/v1/profile/getLiveAccountDetail',
  '/api/v2/profile/getLiveAccountDetail',
  '/api/v1/profile/getDemoAccountDetail',
  '/api/v2/profile/getDemoAccountDetail',
  '/api/v1/viewFilter/save',
  '/api/v2/viewFilter/save',
  '/api/v1/viewFilter/delete',
  '/api/v2/viewFilter/delete',
  '/api/v1/viewFilter/list',
  '/api/v2/viewFilter/list',
  '/api/v1/profile/getClientStatusTimeLine',
  '/api/v2/profile/getClientStatusTimeLine',
];
